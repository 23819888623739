import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import avatar from "../../../images/avatar.png";
import Notification from "../../../images/notifivation-Icon.png";
import overviewicon from "../../../images/overview-icon.svg";
import modulesicon from "../../../images/your-modules-icon.svg";
import modulelibrary from "../../../images/module-library.svg";
import personalsetting from "../../../images/personal-setting.svg";
import closecircle from "../../../images/close-circled.svg";
import skillsicon from "../../../images/skills-icon.svg";
import { saveState } from "../../../store/LocalStorage";
import { connect } from "react-redux";
import report from "../../../images/report.svg";
import { get } from "lodash";
import $ from "jquery";
import Swal from "sweetalert2";

import * as authActions from "../../../action/authActions";

const Sidebar = (props) => {
  const { auth } = props;
  const history = useHistory();

  const [over, setover] = useState(false);
  const [Your, setYour] = useState(false);
  const [Skill, setSkill] = useState(false);
  const [ModuleLi, setModuleLi] = useState(false);
  const [Personal, setPersonal] = useState(false);
  const [NavResponce, setNavResponce] = useState(false);

  const [reportMenu, setreport] = useState(false);

  const showNotification = () => {};

  const handleOver = () => {
    setreport(false);
    setover(true);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
  };
  const handleReport = () => {
    setreport(true);
    setover(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
  };
  const handleYour = () => {
    setreport(false);

    setYour(true);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
  };
  const handleSkill = () => {
    setreport(false);
    setSkill(true);
    setYour(false);
    setover(false);
    setModuleLi(false);
    setPersonal(false);
  };
  const handleModule = () => {
    setreport(false);

    setModuleLi(true);
    setYour(false);
    setover(false);
    setSkill(false);
    setPersonal(false);
  };
  const handlePersonal = () => {
    setreport(false);
    setPersonal(true);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
  };
  const logoutUser = () => {
    Swal.fire({
      allowOutsideClick: false,
      title: "Are you sure?",
      text: "You want to Logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        saveState({});
        authActions.logout();
        history.push("/signin");
      }
    });

    // window.location.pathname = "/signin";
  };

  const moveNotificatio = () => {
    history.push("/notification");
  };

  const handleNavClose = () => {
    $(".navbar-collapse").removeClass("show");
  };

  return (
    <>
      <nav id="mainNav" className="navbar navbar-expand-lg side-bar">
        <div className="collapse navbar-collapse sec2" id="navbarResponsive">
          <div className="sec1 logo d-none d-lg-block">
            <img
              src={EldcLogo}
              style={{ width: "200px", objectPosition: "left" }}
            />
          </div>
          <ul className="navbar-nav">
            <a href="#" className="h-close" onClick={handleNavClose}>
              <img src={closecircle} alt="" />
            </a>
            <li
              className={over ? "active nav-item" : "nav-item"}
              onClick={handleOver}
            >
              <Link to="/user-dashboard" className="nav-link">
                <img src={overviewicon} alt="Profile Picture" />
                <span>Overview</span>
              </Link>
            </li>
            <li
              className={Your ? "active nav-item" : "nav-item"}
              onClick={handleYour}
            >
              <Link to="/your-module" className="nav-link">
                <img src={modulesicon} alt="Profile Picture" />
                <span>Your Modules</span>
              </Link>
            </li>
            <li
              className={Skill ? "active nav-item" : "nav-item"}
              onClick={handleSkill}
            >
              <Link to="/skills" className="nav-link">
                <img src={skillsicon} alt="Profile Picture" />
                <span>Skill</span>
              </Link>
            </li>
            <li
              className={ModuleLi ? "active nav-item" : "nav-item"}
              onClick={handleModule}
            >
              <Link to="/module-library" className="nav-link">
                <img src={modulelibrary} alt="Profile Picture" />{" "}
                <span> Module Library</span>
              </Link>
            </li>
            <li
              className={Personal ? "active nav-item" : "nav-item"}
              onClick={handlePersonal}
            >
              <Link to="profile" className="nav-link">
                <img src={personalsetting} alt="Profile Picture" />{" "}
                <span> Personal Settings</span>
              </Link>
            </li>
            <li className="sec3 prfile">
              <div className="row justify-content-between">
                <div className="avatar">
                  <img
                    src={get(auth, "user.profilePic.Location") || avatar}
                    alt="Profile Picture"
                  />
                  {/* <img src={get(auth, 'user.profilePic.Location') || Profile} alt="Profile Picture" /> */}
                </div>
                {/* <div className="position-relative">
                  <img
                    src={Notification}
                    onClick={moveNotificatio}
                    alt="Profile Picture"
                  />
                  <span>5</span>
                </div> */}
              </div>

              <h3>{auth.user.name}</h3>
              <span>{auth.user.role}</span>
              <p>{auth.user.emailAddress}</p>
              <Link
                to="profile"
                className="primary_link d-inline-block border-0 ps-0"
              >
                View profile
              </Link>
              <Link
                className="secondary-btn d-inline-block p-2 text-center border-0 "
                onClick={logoutUser}
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Sidebar);
