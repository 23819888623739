import React, { useEffect, useState } from "react";
import chartone from "../../../images/chartone.jpg";
import arrowdown from "../../../images/arrow-down-left.svg";
import * as userdashboardActions from "../../../action/userdashboardActions";
import * as moduleActions from "../../../action/moduleActions";
import * as admindashboardActions from "../../../action/adminDashboardActions";
import {
  getMonthListTillDate,
  getValueByName,
} from "../../../common/utilityFunctions";
import {
  HorizontalBarChart,
  StackedBarChart,
  HorizontalBarChartToday,
} from "../../../common/chart";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { loadState } from "../../../store/LocalStorage";
import Search from "../../common/Search";
import { get } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const getMonthListTillNow = getMonthListTillDate();

const MainContent = (props) => {
  console.log("user page");
  const { moduleActionsCreator, moduleReport } = props;
  const [allModules, setAllModules] = useState([]);
  const [stackChartData, setStackChartData] = useState([]);
  let [modules, setModules] = useState(null);
  let [modulesSingle, setModulesSingle] = useState(null);

  const { loggedIn, user, token } = get(loadState(), "auth") || {};
  const [selectedModule, setSelectedModule] = useState(user);
  localStorage.setItem("selectedModule", JSON.stringify(user));
  let [peerComparison, setPeerComparison] = useState(null);
  let [engagementMetrices, setEngagementMetrices] = useState(null);
  let [averageScore, setAverageScore] = useState(null);
  let [modulesStatus, setModulesStatus] = useState(null);
  const [Lavel, setLavel] = useState(false);
  const [itemLi, setItemLi] = useState(null);
  let [searchTerm, setsearchTerm] = useState("");
  let [coursesOverview, setCoursesOverview] = useState(null);
  let [loading, setLoading] = useState(false);

  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    user: {
      ...user,
    },
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log("under window");
    } else {
      console.log("old window");
    }
    setLoading(true)
    userdashboardActions.getAllModulesWithScore()
      .then((res) => {
        setAllModules(res.data);
      })
      .finally(() => setLoading(false));
    loadDateForStackChart(new Date().toISOString());
  }, []);

  useEffect(() => {
    // console.log('module report',moduleReport)
    var sData = {
      moduleName: moduleReport?.moduleReport?.[0]?.moduleName,
      Avarage: (
        ((Math.min(
          ...moduleReport?.moduleReport?.map((item) => item?.moduleAverageScore)
        ) +
          Math.max(
            ...moduleReport?.moduleReport?.map(
              (item) => item?.moduleAverageScore
            )
          )) /
          2) *
        100
      ).toFixed(1),
      Min: (
        Math.min(
          ...moduleReport?.moduleReport?.map((item) => item?.moduleAverageScore)
        ) * 100
      ).toFixed(1),

      Max: (
        Math.max(
          ...moduleReport?.moduleReport?.map((item) => item?.moduleAverageScore)
        ) * 100
      ).toFixed(1),
    };

    setModulesSingle([sData]);
  }, [moduleReport]);

  useEffect(() => {
    loadUserModules();
    loadUserEngagementMetrices();
    loadUserAverageScore();
    loadUserModulesStatus();
    fetchCoursesOverview();
    userdashboardActions.getAllModulesWithScore().then((res) => {
      setAllModules(res.data);
    });
  }, []);
  const loadUserAverageScore = () => {
    userdashboardActions
      .getUserAverageScore()
      .then((response) => setAverageScore(response.data.score));
  };

  const fetchCoursesOverview = () => {
    admindashboardActions
      .getCoursesOverview()
      .then((response) => {
        const coursesOverview =
          response.data &&
          response.data.length > 0 &&
          response.data.slice(0, 5);
        const coursesOverviewDataToShowOnGraph =
          coursesOverview &&
          coursesOverview.map((item) => {
            return {
              moduleName: get(item, "_id.moduleName"),
              passed: get(item, "passed"),
            };
          });
        setCoursesOverview(coursesOverviewDataToShowOnGraph);
      })
      .catch((error) =>
        console.error(
          `There was error while fetching Courses overview ${error.response.data.message}`
        )
      );
  };

  const loadUserModulesStatus = () => {
    userdashboardActions
      .getUserModuleStatus()
      .then((response) => setModulesStatus(response.data));
  };

  const loadUserEngagementMetrices = () => {
    userdashboardActions
      .getUserEngagementMetrices()
      .then((response) => setEngagementMetrices(response.data))
      .catch((error) =>
        console.error(
          `failed to get user engagement metrices  ${error.response.data.message}`
        )
      );
  };

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const loadUserModules = () => {
    moduleActions
      .fetchUserModules(auth.token)
      .then((response) => {
        let mdls = [];
        for (let j = 0; j < response?.data.length; j++) {
          for (let k = 0; k < user.modules.length; k++) {
            if (response?.data[j]._id === user.modules[k].moduleId) {
              mdls.push(response?.data[j]);
            }
          }
        }
        mdls = removeDuplicates(mdls);
        setModules(mdls);
        if (response.data && response.data.length > 0)
          loadUserPeerComparison(response.data[0]._id);
      })
      .catch((error) =>
        console.error(
          `failed to get user modules data  ${error.response.data.message}`
        )
      );
  };

  const loadUserPeerComparison = (selectedModuleId) => {
    userdashboardActions
      .getUserPeerComparison(selectedModuleId)
      .then((response) => {
        setPeerComparison(response.data);
      })
      .catch((error) =>
        console.error(
          `failed to get user peer comparison data  ${error.response.data.message}`
        )
      );
  };

  const loadDateForStackChart = (selectedDate) => {
    userdashboardActions
      .getUserModulesTimeSpendByMonth(selectedDate)
      .then((res) => setStackChartData(res.data));
  };
  const onItemSelected = (module) => {
    setLavel(true);
    setItemLi(module);
  };

  const selectModule = (event) => {
    loadUserPeerComparison(event.target.value);
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-9 col-lg-8 overview-left-area">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src={EldcLogo} alt="" />
                </a>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <h2 className="right_hading d-none d-md-inline">Dashboard</h2>
            </div>
            <h2 className="right_hading d-md-none d-block">Dashboard</h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="sec2">
                  <form action="">
                    <div className="sec2-header">
                      <h2>DEVICE ACTIVITY </h2>
                      <div className="dropdown ">
                        <span
                          className="dropdown-toggle-change me-2"
                          id="dropdownMenuButton1"
                        >
                          View By
                        </span>
                        <select
                          style={{ minWidth: "100px" }}
                          onChange={(event) => {
                            loadDateForStackChart(event.target.value);
                          }}
                        >
                          {getMonthListTillNow.map((item) => (
                            <option value={item.dateISO}>
                              {item.monthSmallName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <StackedBarChart {...{ stackChartData }} />
                  </form>
                </div>
              </div>

              {/*  */}

              <div className="col-lg-12">
                <div className="sec2">
                  <form action="">
                    <div className="sec2-header">
                      <h2>Module Performance </h2>
                      <div className="dropdown ">
                        <span
                          className="dropdown-toggle-change me-2"
                          id="dropdownMenuButton12"
                        >
                          Select Module
                        </span>
                        <select
                          style={{ minWidth: "100px" }}
                          onChange={(e) => {
                            moduleActionsCreator.getModuleReport(
                              e.target.value,
                              user._id
                            );
                          }}
                        >
                          {modules?.map((item) => (
                            <option value={item._id}>{item.moduleName}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <HorizontalBarChart
                      data={modulesSingle}
                      dataKey="moduleName"
                      barColors={["#00D0B5", "#1b74e0", "#a331f5", "#f531e5"]}
                      barSize={25}
                      axisLine={true}
                      tickLineXaxis={true}
                      width={700}
                      height={300}
                    />
                    <div className="barchart-yaxis-text">SCORE</div>
                  </form>
                </div>
              </div>
            </div>
            {!loading ?
              <></>
              :
              <div className="loader-wrapper">
                <div className="loader"></div>

              </div>

            }
          </div>
          <div className="col-xl-3 col-lg-4 right-side-area">
            <form action="" className="search">
              <input
                type="text"
                placeholder="search Item ..."
                onChange={(event) => {
                  setsearchTerm(event.target.value);
                }}
              />
            </form>
            <div className="module-sts">
              <h3>Module Status</h3>
              <ul>
                {allModules &&
                  allModules
                    .filter((val) => {
                      if (searchTerm == "") {
                        return val;
                      } else if (
                        val.moduleName
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((module, key) => {
                      return (
                        <li key={key}>
                          <span>{module.moduleName}</span>
                          <label>{module.score}</label>
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div className="devider"></div>
            <div className="module-sts sec-2">
              <h3>Engagement</h3>
              <ul>
                <li>
                  <span>No. of Sessions</span>{" "}
                  {engagementMetrices && engagementMetrices.noOfSessions}
                </li>
                <li>
                  <span>Available Scenarios</span>
                  {engagementMetrices && engagementMetrices.availableScenarios}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleReport: state.modules.moduleReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActionsCreator: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
