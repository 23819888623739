import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import plus from "../../../images/plus.svg";
import { initialize, destroy } from "redux-form";

import Search from "../../common/Search";
import Background from "../../../images/company-profile-bg.jpg";
import DataGrid from "../../../common/dataGrid";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";
import { GrAdd } from "react-icons/gr";
import { FiEdit3 } from "react-icons/fi";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModulePage = (props) => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("moduleName");
  const [sortOrder, setSortOrder] = useState(1);
  const [modules, setModules] = useState([]);
  const [modulesCount, setModulesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  let [moduleAll, setModuleAll] = useState([]);
  const inputRef = useRef(null);
  let [searchTerm, setsearchTerm] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const customStyles = {
    headCells: {
      style: {
        "border-top": "none",
        "font-size": "15px",
        color: "#2F4153",
        "font-weight": 700,
        "&:last-child": {
          "text-align": "right",
        },
      },
    },
  };

  const statusCell = (row) => {
    return (
      <div className="statusText">
        {row.status ? (
          <label className="badge text-bg-success">Active</label>
        ) : (
          <label className="badge text-bg-secondary">Inactive</label>
        )}
      </div>
    );
  };
  const actionCell = (row) => {
    return (
      <div style={{ textAlign: "right" }}>
        <span
          type="submit"
          className="primary_link"
          onClick={() => {
            history.push(`/module-management?moduleId=${row._id}`);
            // selectedModule(row, "module-management");
          }}
        >
          <FiEdit3 />
        </span>
      </div>
    );
  };

  const columns = [
    { name: "Module Name", selector: "moduleName", sortable: true, wrap: true },
    { name: "Module Type", selector: "moduleType", sortable: true },
    { name: "Status", cell: statusCell, selector: "status", sortable: true },
    { name: "Action", cell: actionCell },
  ];

  useEffect(() => {
    //props.moduleActions.getAllModules(auth.token);
    loadModules();
  }, [sortColumn, sortOrder, pageSize, pageNumber]);

  const loadModules = () => {
    setLoading(true);
    moduleActions
      .fetchModules(pageNumber, pageSize, sortOrder, sortColumn)
      .then((response) => {
        setModules(response.data.modules);
        setModulesCount(response.data.modulesCount);
      })
      .catch((error) =>
        //  toast.error(error.data.response)
        Swal.fire({
          title: "Error",
          text: `Error: ${error}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
      )
      .finally(() => setLoading(false));
  };

  const onChangePage = (page, totalRows) => {
    setPageNumber(page);
  };
  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setPageNumber(1);
    setPageSize(currentRowsPerPage);
  };
  const onSort = (column, sortDirection, event) => {
    setSortColumn(column.selector);
    setSortOrder(sortDirection === "asc" ? 1 : -1);
  };
  const selectedModule = (data, goto) => {
    props.moduleActions.selectedModule(data);
    history.push(`/${goto}`);
  };
  const addModule = () => {
    props.dispatch(destroy("addmodule"));
    history.push("add-module");
  };
  const onItemSelected = (module) => {
    console.log("module seelect", module);
    props.moduleActions.selectedModule(module);
    history.push(`/module-management?moduleId=${module._id}`);
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    toggle();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  const searchUsermod = () => {
    setLoading(true);

    moduleActions
      .searchModules(searchTerm)
      .then((response) => {
        // console.log("selecct alll user", response.data)
        setModuleAll(response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">All Modules</h2>
              <form action="" className="search mt-3">
                {/* <Search
                  {...{
                    data: modules,
                    onItemSelected,
                    populateFieldName: "moduleName",
                    placeholderLabel: "Search Module",
                  }}
                /> */}
                <input
                  type="text"
                  onClick={handleSearchClick}
                  placeholder="Search..."
                />
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  {...props}
                  className="search-modal"
                >
                  <ModalBody>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group">
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Search modules..."
                            onChange={(event) => {
                              setsearchTerm(event.target.value);
                            }}
                          />
                          {/* <button className="custombtnSearch" onClick={searchUsermod}> search</button> */}
                          <button
                            className="custombtnSearch"
                            onClick={searchUsermod}
                            disabled={!searchTerm}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="list-container">
                          {console.log("module value", moduleAll)}
                          <ul>
                            {moduleAll && (
                              <div className="list-container">
                                <ul>
                                  {moduleAll
                                    .filter((val) => {
                                      if (searchTerm === "") {
                                        return true; // Show all items if searchTerm is empty
                                      } else if (
                                        val.moduleName
                                          ?.toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      ) {
                                        return true; // Show items that match the searchTerm
                                      }
                                      return false; // Hide items that don't match
                                    })
                                    .map(function (item, key) {
                                      return (
                                        <li
                                          key={key}
                                          onClick={() => onItemSelected(item)}
                                        >
                                          <div
                                            className="mainSearchBar w-100"
                                            style={{
                                              zIndex: 10,
                                              textAlign: "left",
                                            }}
                                          >
                                            <div className="desc">
                                              <span className="text-left">
                                                {item.moduleName}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* {usersAll.length > 5 ?

                          <div className="col-md-12">
                            <div className="d-flex justify-content-center py-2">
                              <button className="paginationBtn"
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                              >
                                <FcPrevious />
                              </button>
                              <button className="paginationBtn"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    Math.min(prev + 1, Math.ceil(usersAll.length / itemsPerPage))
                                  )
                                }
                                disabled={currentPage === Math.ceil(usersAll.length / itemsPerPage)}
                              >
                                <FcNext />
                              </button>

                            </div>
                          </div>
                          : null} */}
                    </div>
                  </ModalBody>
                </Modal>
              </form>

              <a
                href=""
                className="adcm-btn ms-2 px-3 mt-0"
                onClick={addModule}
              >
                <GrAdd /> Add Module
              </a>
            </div>
            <h2 className="right_hading d-md-none d-block">All Modules</h2>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive">
                  <DataGrid
                    noHeader
                    data={modules}
                    paginationTotalRows={modulesCount}
                    columns={columns}
                    onChangePage={onChangePage}
                    onSort={onSort}
                    keyField="_id"
                    customStyles={customStyles}
                    paginationPerPage={pageSize}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    // progressPending={loading}
                    sortServer={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {!loading ? (
            <></>
          ) : (
            <div className="loader-wrapper">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleList: state.modules.modulesList,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModulePage);
