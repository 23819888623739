import React from "react";
import VideoModal from "../../../common/api/VideoModel";

const Libraritest = () => {
  return (
    <>
      {/* <div class="overview-cont">
                <div class="row">
                    <div class="col-xl-9 col-lg-8 overview-left-area">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="sec1 logo d-block d-lg-none">
                                <a href="index.html">
                                    <img src="assets/images/MedVR_Logo.png" alt="" />
                                </a>
                            </div>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                                aria-expanded="false" aria-label="Toggle navigation"><span
                                    class="navbar-toggler-icon"></span>
                            </button>

                        </div>

                        <h2 class="right_hading"> <i class="fa-solid fa-arrow-left"></i> Test Final</h2>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="sec2 ">
                                    <div class="abt-top-pic">
                                        <span class="green-box"></span>
                                        <img src="assets/images/about-bg.png" alt="" />
                                    </div>
                                    <div class="devider2"></div>
                                    <div class="cms-content">
                                        <h5>DESCRIPTION</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida
                                            pulvinar purus, id consequat justo. Sed convallis rhoncus orci ac
                                            imperdiet. Sed ut dignissim felis, in suscipit ipsum. Nullam consectetur
                                            laoreet justo. Phasellus imperdiet, tortor ut consequat ultricies,
                                            turpis purus vehicula purus, vitae interdum purus augue sed lacus.
                                            Vivamus eu ex dictum libero accumsan sagittis. Nulla facilisi. Proin
                                            interdum felis non leo consectetur viverra. In enim dui, maximus vitae
                                            mi in, convallis facilisis odio. In hendrerit aliquam tellus, a
                                            elementum massa. Praesent metus risus, dignissim et tellus non,
                                            porttitor lacinia diam.</p>

                                        <p>In ultricies neque vitae dui volutpat, eu congue urna ultrices. In hac
                                            habitasse platea dictumst. Vivamus est mauris, dictum vel elementum in,
                                            mattis eu nisi. Integer fringilla velit et leo rutrum, vel pulvinar
                                            felis tristique. Fusce blandit aliquet dui, vitae malesuada orci varius
                                            quis. Aliquam commodo rhoncus nibh sit amet posuere. Donec porta ut arcu
                                            et lobortis. Fusce pellentesque tincidunt nulla, id aliquam leo dictum
                                            sit amet. Aliquam quam justo, tempus non mattis ac, sollicitudin nec
                                            augue. Mauris condimentum arcu vitae risus feugiat tempus. Nullam
                                            tincidunt, ante vitae suscipit varius, risus ligula cursus nibh, ut
                                            tristique orci turpis ac mi.</p>

                                        <p>Etiam ac cursus nulla. Phasellus egestas aliquam orci vitae tempus. In
                                            molestie placerat nisi ac venenatis. Maecenas rhoncus non eros sed
                                            sagittis. Praesent dapibus eleifend ligula id ornare. Mauris sed elit
                                            non arcu egestas lacinia. Ut sit amet elementum neque. Cras in elit
                                            vitae lorem condimentum congue id nec diam.</p>


                                        <p>Quisque malesuada ornare nisl id varius. Fusce lobortis ipsum dictum
                                            ligula commodo, eget finibus nulla volutpat. Aenean a varius turpis.
                                            Donec accumsan interdum diam, nec semper velit condimentum sit amet.
                                            Nulla ultrices malesuada lectus. Sed porta risus eget velit placerat,
                                            non volutpat est tempor. Nunc ut urna eget felis rhoncus blandit non ac
                                            lorem. Nulla facilisi. Cras tempor posuere mauris ac maximus.</p>

                                        <p>Etiam elementum nisi et bibendum hendrerit. Pellentesque quis imperdiet
                                            tortor, ut semper mauris. Donec lacinia sed neque in suscipit.
                                            Pellentesque commodo ut ante sit amet efficitur. Morbi finibus ante ut
                                            feugiat aliquam. Proin non gravida nulla, et venenatis nisl. Mauris
                                            vestibulum lorem eget nibh bibendum, non sollicitudin lectus venenatis.
                                        </p>
                                        <div class="seemore-block">
                                            <a href="#">Show More <img src="assets/images/caret-down.svg"
                                                alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 right-side-area">
                        <a class="video-area" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <img src="assets/images/VideoPlayer.png" alt="" />
                            <span class="play"><i class="fa-solid fa-caret-right"></i> </span>
                        </a>

                        <a href="#" class="dl_module"> Download Module </a>

                        <a href="#" class="dl_guide"> Download Guide </a>
                    </div>
                </div>
                <div class="modal video-modal fade modal-fullscreen" id="staticBackdrop" data-bs-backdrop="static"
                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <video id="wp_mep_1" controls="" width="100%" height="auto">

                                    <source type="video/mp4" src="http://techslides.com/demos/sample-videos/small.mp4"> </source>
                                </video>
                            </div>

                        </div>
                    </div>
                </div>

            </div> */}
      <div class="overview-cont">
        <div class="row">
          <div class="col-xl-9 col-lg-8 overview-left-area">
            <div class="d-flex justify-content-between align-items-center">
              <div class="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src="assets/images/MedVR_Logo.png" alt="" />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <h1>Hellow vedio </h1>
                <VideoModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Libraritest;
