import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import * as moduleActions from "../../../action/moduleActions";
import * as companyActions from "../../../action/companyActions";
import * as userActions from "../../../action/userActions";
import {
  addUser,
  getUsersMobileNo,
  getRoles,
} from "../../../action/userActions";
import * as departmentActions from "../../../action/departmentActions";

import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import Swal from "sweetalert2";
import validate from "./UserValidator";
import { bind, get } from "lodash";
import MobileNavBar from "../../../components/mobileNavBar";
import {
  renderInputField,
  renderRadioButtonField,
  RenderImageField,
} from "../../../common/InputFields";

import Search from "../../common/Search";
import axios from "axios";
import { getDepartment } from "../../../action/departmentActions";

let AddCompanyUser = (props) => {
  const { handleSubmit, allModulesByCompany, token, user, auth } = props;
  const history = useHistory();
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [errorBorderCss, setBorderCss] = useState(null);
  let [touched, setTouched] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [usersMobileNos, setUsersMobileNos] = useState([]);
  let [allDepartments, setAllDepartments] = useState([]);
  let [departmentName, setdepartmentName] = useState(null);
  let [roles, setRoles] = useState([]);
  const [profilePic, setProfilePic] = useState(Ellipse9);
  const [department, setDepartment] = useState({});
  let [moduleList, setAllModules] = useState([]);

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);
  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);
    getUsersMobileNo().then((res) => setUsersMobileNos(res.data || []));
    loadAllDepartments();
    getRoles().then((data) => {
      setRoles(data.data);
    });
    userActions.getModules().then((response) => {
      setAllModules(response.data);
    });
  }, []);
  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => {
        setAllDepartments(response.data);
        if (user.role == "Manager") {
          let updatedDepartments = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < user.departmentId.length; j++) {
              if (user.departmentId[j] == response.data[i]._id) {
                updatedDepartments.push(response.data[i]);
              }
            }
          }
          setAllDepartments(updatedDepartments);
        }
      })
      .catch((error) => {
        // alert(JSON.stringify(err));
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        // setLoading(false);
      });
  };

  const generateUserID = () => {
    for (let i = 0; i < 10; i++) {}
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  };

  const generatePin = () => {
    return Math.floor(Math.random() * 10000);
  };

  const [mobileNo, setMobileNo] = useState(generateUserID());
  const [pin, setPin] = useState(generatePin());

  const handleInput = (e) => {
    setMobileNo(e.target.value);
  };

  const AddUser = (values = {}) => {
    values.mobileNo = mobileNo.toString();
    values.pin = pin;
    if (usersMobileNos.find((mobile) => mobile.mobileNo === values.mobileNo)) {
      Swal.fire({
        title: "Error",
        text: "Mobile no is already registered in our database",
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      const modules =
        selectedModules &&
        selectedModules.map((item) => {
          return { moduleId: item._id, status: true };
        });
      const companyId = props.user.companyId;

      values.companyId = companyId;
      values.modules = modules;
      values.profilePic = profilePic;
      if (role == "Manager") {
        values.departments = selectedDepartments;
      } else {
        values.department = departmentName;
      }
      values.role = role;

      addUser(values)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "User Added Successfully",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(function () {
            if (user.role === "SuperAdmin") {
              history.push("/user-management");
            } else {
              history.push("/user-management");
            }
          });
        })
        .catch((error) => {
          // alert.error(error.response.data.message ? error.response.data.message : error.response.data);
          Swal.fire({
            title: "Error",
            text: `Error: ${
              error.response.data.message
                ? "Please enter all fields!"
                : error.response.data
                ? error.response.data
                : error.message
            }`,
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        });
    }
  };
  const onModuleItemSelected = (module) => {
    if (
      !selectedModules.find(
        (selectedModule) => selectedModule._id === module._id
      )
    )
      setSelectedModules([...selectedModules, module]);
  };

  const onDepartmentItemSelected = (department) => {
    if (
      !selectedDepartments.find(
        (selectedDepartment) => selectedDepartment._id === department._id
      )
    )
      setSelectedDepartments([...selectedDepartments, department]);
  };

  const onRemoveModuleClick = (e, moduleID) => {
    e.stopPropagation();
    const newSelectedModules = selectedModules.filter(
      (item) => item._id !== moduleID
    );
    setSelectedModules([...newSelectedModules]);
  };

  const onRemoveDepartmentClick = (e, departmentId) => {
    e.stopPropagation();
    const newSelectedDepartments = selectedDepartments.filter(
      (item) => item._id !== departmentId
    );
    setSelectedDepartments(newSelectedDepartments);
  };
  const onUpload = (event) => {
    console.log(get(event, "target.files[0]"));
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setProfilePic(res);
    });
  };
  const handleChangeDrop = (event) => {
    setdepartmentName(event.target.value);
    // setSelectedUsers([...selectedUsers, user]);
  };

  const [role, setRole] = useState("");

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form onSubmit={handleSubmit(AddUser)}>
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">
                  Add A New User
                </h2>

                <div className="mt-3">
                  {/* <a href="#" className="adcm-btn Cancel ms-1"> Cancel</a> */}
                  <a>
                    {/* <Link
                      to={
                        user.role === "SuperAdmin"
                          ? "/user-management"
                          : "/company-user-management"
                      }
                      className="adcm-btn Cancel ms-1"
                    > */}
                    <Link
                      to={"/user-management"}
                      className="adcm-btn Cancel ms-1"
                    >
                      <span>Return</span>
                    </Link>
                  </a>
                  <a className="adcm-btn ms-2">
                    <button
                      type="submit"
                      className="btn"
                      style={{ color: "#fff" }}
                    >
                      Add
                    </button>
                  </a>
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">Dashboard</h2>

              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main">
                    <div
                      className="company-profile"
                      style={modifyBackgroundStyle}
                    ></div>
                    <div className="company-fillout">
                      <div className="circle-outer">
                        <div className="circle">
                          {/* <img src={Ellipse9} alt="" /> */}
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                        </div>
                        <div className="p-image">
                          {/* <img className="upload-button" src="assets/images/camera.svg" alt="" /> */}

                          {/* <input className="file-upload" type="file" accept="image/*" /> */}
                          <div
                            className="photoEdit mt-3"
                            style={{ position: "relative" }}
                          >
                            <label for="jdj">
                              <img
                                className="profile-pic"
                                src={camera}
                                alt=""
                              />
                            </label>
                            <input
                              id="jdj"
                              onChange={onUpload}
                              accept={".jpg, .jpeg, .png"}
                              type="file"
                              style={{
                                opacity: 0,
                                width: "100%",
                                position: "absolute",
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <h3 className="fill-heaing">User Information</h3>
                      <div className="row align-items-end">
                        <div className="col-md-6">
                          {/* <div className="form-group">
                            <label>Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              autocomplete="off"
                            />
                          </div> */}
                          <div className="form-group">
                            <label>User Name</label>
                            <Field
                              name="name"
                              component={renderInputField}
                              type="text"
                              label="User name"
                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputName2">Password</label>
                            <input
                              name="emailAddress"
                              component={renderInputField}
                              type="number"
                              label="Enter 4 digit Code"
                              placeholder="Enter 4 digit Code"
                              className="form-control"
                              onChange={(e) => setPin(e.target.value)}
                              value={pin}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>User ID</label>
                            {/* <input type="text" className="form-control" placeholder="Please Select" autocomplete="off" /> */}
                            <input
                              name="mobileNo"
                              component={renderInputField}
                              className="form-control"
                              type="number"
                              label="Enter 10 digit User ID"
                              placeholder="Enter 10 digit User ID"
                              onChange={(e) => handleInput(e)}
                              value={mobileNo}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputName2">Email ID</label>
                            <Field
                              name="emailAddress"
                              component={renderInputField}
                              type="email"
                              label="Email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Additional Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Role</label>
                            <select
                              className="form-control select2"
                              onChange={handleRoleChange}
                            >
                              <option value="">Select Role</option>
                              {roles?.map((item) => {
                                if (user.role === "Manager") {
                                  if (item.role === "User") {
                                    return (
                                      <option value={item.role} key={item._id}>
                                        {item.role}
                                      </option>
                                    );
                                  }
                                } else if (user.role !== "Manager") {
                                  return (
                                    <option value={item.role} key={item._id}>
                                      {item.role}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Course</label>
                            <Field
                              name="employeeId"
                              component={renderInputField}
                              type="text"
                              label="Course Name"
                            />
                          </div>
                          <div className="form-group">
                            {role == "Manager" ? (
                              <>
                                <div
                                  className="flex2fieldForm"
                                  style={{ marginTop: "3.65em" }}
                                >
                                  <div className="field-group">
                                    <label>Department</label>
                                    <Search
                                      {...{
                                        data: allDepartments,
                                        onItemSelected:
                                          onDepartmentItemSelected,
                                        populateFieldName: "departmentName",
                                        placeholderLabel: "Select Departments",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="flex2fieldForm">
                                  <div className="field-group">
                                    {selectedDepartments &&
                                      selectedDepartments.length > 0 && (
                                        <div className="flex2fieldForm">
                                          <div className="field-group">
                                            <div
                                              className="radioGroup"
                                              style={{ paddingTop: "1.65em" }}
                                            >
                                              {selectedDepartments.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      key={`${item}-${index}`}
                                                      className="mr-4 moduleCompetencyCard"
                                                    >
                                                      <label htmlFor="Module">
                                                        {item.departmentName}
                                                      </label>
                                                      <button
                                                        onClick={(e) =>
                                                          onRemoveDepartmentClick(
                                                            e,
                                                            item._id
                                                          )
                                                        }
                                                      >
                                                        X
                                                      </button>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <label style={{ marginTop: "1.65em" }}>
                                  Department
                                </label>

                                <select
                                  className="form-control select2"
                                  onChange={handleChangeDrop}
                                >
                                  <option value="">Select Department</option>
                                  {allDepartments &&
                                    allDepartments.length > 0 &&
                                    allDepartments.map((result, i) => {
                                      return (
                                        <option
                                          value={result.departmentName}
                                          key={result._id}
                                        >
                                          {result.departmentName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Location</label>
                            <Field
                              name="location"
                              component={renderInputField}
                              type="text"
                              label="Location"
                            />
                          </div>

                          {role != "Manager" && (
                            <div className="form-group">
                              <div
                                className="flex2fieldForm"
                                style={{ marginTop: "3.65em" }}
                              >
                                <div className="field-group">
                                  <label>Modules</label>
                                  <Search
                                    {...{
                                      data: moduleList,
                                      onItemSelected: onModuleItemSelected,
                                      populateFieldName: "moduleName",
                                      placeholderLabel: "Select Modules",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="flex2fieldForm">
                                <div className="field-group">
                                  {selectedModules &&
                                    selectedModules.length > 0 && (
                                      <div className="flex2fieldForm">
                                        <div className="field-group">
                                          <div
                                            className="radioGroup"
                                            style={{ paddingTop: "1.25em" }}
                                          >
                                            {selectedModules.map(
                                              (item, index) => (
                                                <div
                                                  key={`${item}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item.moduleName}
                                                  </label>
                                                  <button
                                                    onClick={(e) =>
                                                      onRemoveModuleClick(
                                                        e,
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

AddCompanyUser = reduxForm({
  // a unique name for the form
  form: "addcompanyuserpage",
  validate,
})(AddCompanyUser);

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    user: state.auth.user,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyUser);

// export default AddCompanyUser;
