import React, { useEffect, useState, useRef } from "react";
import { Multiselect } from "react-widgets";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Search from "../../common/Search";
import * as moduleActions from "../../../action/moduleActions";
import * as userActions from "../../../action/userActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AssignmentPage = (props) => {
  const { auth, allUsersByCompany = [] } = props || {};
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  let [moduleList, setAllModules] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    // props.moduleActions.getAllModules(auth.token);

    // props.userActions.getAllUsersByCompany(auth.token, auth.user.companyId);
    // loadAllModules();
    userActions.getModules().then((response) => {
      setAllModules(response.data);
    });
  }, []);

  const loadAllModules = () => {
    setLoading(true);
    if (props.auth?.user?.role == "Manager") {
      moduleActions
        .getModuleByUser()
        .then((response) => setAllModules(response.data))
        .catch((error) =>
          // toast.error(`Unable to fetch modules ${error.data}`)
          Swal.fire({
            allowOutsideClick: false,
            title: "Error",
            text: `Unable to fetch modules: ${error}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        )
        .finally(() => setLoading(false));
    } else {
      moduleActions
        .fetchAllModulesByCompany()
        .then((response) => setAllModules(response.data))
        .catch((error) =>
          // toast.error(`Unable to fetch modules ${error.data}`)
          Swal.fire({
            allowOutsideClick: false,
            title: "Error",
            text: `Unable to fetch modules: ${error}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        )
        .finally(() => setLoading(false));
    }
  };
  const onUserItemSelected = (user) => {
    setSelectedUsers([...selectedUsers, user]);
  };
  const onModuleItemSelected = (module) => {
    setSelectedModules([...selectedModules, module]);
  };
  // const removeUser = (userID) => {
  //     const newSelectedUsers = selectedUsers.filter(item => item._id !== userID);
  //     setSelectedUsers([...newSelectedUsers]);
  // }
  const onRemoveUserClick = (e, userID) => {
    e.stopPropagation();
    const newSelectedUsers = selectedUsers.filter(
      (item) => item._id !== userID
    );
    setSelectedUsers([...newSelectedUsers]);
  };

  // const removeModule = (moduleID) => {
  //     const newSelectedModules = selectedModules.filter(item => item._id !== moduleID);
  //     setSelectedModules([...newSelectedModules]);
  // }
  const onRemoveModuleClick = (e, moduleID) => {
    e.stopPropagation();
    const newSelectedModules = selectedModules.filter(
      (item) => item._id !== moduleID
    );
    setSelectedModules([...newSelectedModules]);
  };

  const assignModulesToUsers = () => {
    setLoading(true);
    const users = selectedUsers.map((item) => item._id);
    const modules = selectedModules.map((item) => item._id);

    console.log("selectedModules", selectedModules);

    userActions
      .assignModulesToUsers(auth.token, { users, modules })
      .then(() => {
        console.log("follow mw");
        setLoading(false);
        Swal.fire({
          allowOutsideClick: false,
          title: "Success",
          text: "Selected modules are assigned to the selected users",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Error",
          text: `${(err.message, { type: "error" })}`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  // Search
  // ===================================================

  // let [searchTerm, setsearchTerm] = useState("");
  let [searchUserList, setSearchUserList] = useState("");

  const getSearchVal = (val) => {
    console.log("getSearchVal", val);
    setsearchTerm(val);
  };

  // const searchUsermod = () => {
  //   setLoading(true);
  //   let userAllData = selectedUsers;

  //   userActions
  //     .searchUsers(searchTerm)
  //     .then((response) => {
  //       // response.data.forEach((user) => {
  //       //   userAllData.push(user);
  //       // });
  //       setSearchUserList(response.data);
  //     })
  //     .finally(() => setLoading(false));

  //   // setSearchUserList(userAllData);
  // };

  // Search Modal New
  // ===================================================

  const searchUser = () => {
    console.log(searchUserVal);
    toggle();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  const [searchUserVal, setSearchUserVal] = useState("");
  let [searchTerm, setsearchTerm] = useState("");
  let [usersAll, setUsersAll] = useState([]);
  let [searchUsers, setSearchUsers] = useState([]);
  const [searchIds, setSearchIds] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const inputRef = useRef(null);

  let userSelectData = [];

  userSelectData = selectedUsers.map((item) => {
    return {
      _id: item._id,
      moduleName: item.name,
    };
  });

  const searchUsermod = () => {
    setLoading(true);
    let userAllData = [];

    console.log("searchIds", searchIds);

    usersAll.forEach((user) => {
      searchIds.forEach((sUser) => {
        if (user._id == sUser) {
          userAllData.push(user);
        }
      });
    });

    console.log("userAllData", userAllData);

    userActions
      .searchUsers(searchTerm)
      .then((response) => {
        // setUsersAll(response.data);
        response.data.forEach((user) => {
          userAllData.push(user);
        });

        setUsersAll(userAllData);
      })
      .finally(() => setLoading(false));
  };

  const selectUserSearch = (user) => {
    console.log("selectUserSearch", user);
    let selectedUserList = selectedUsers;
    let searchUserList = searchUsers;

    if (selectedUsers.includes(user)) {
      console.log("user._id.includes(selectedUserList)");
      const index = selectedUserList.indexOf(user);
      if (index > -1) {
        selectedUserList.splice(index, 1);
        searchUserList.splice(index, 1);
      }
    } else {
      console.log("!user._id.includes(selectedUserList)");
      selectedUserList.push(user);
      searchUserList.push({
        _id: user._id,
        moduleName: user.name,
      });
    }

    setSearchUsers(searchUserList);
    setSelectedUsers(selectedUserList);

    console.log("selectedUsers", selectedUsers);
    toggle();
  };

  function HasUserIdTag({ user }) {
    if (!selectedUsers.includes(user)) {
      return (
        <button
          className="btn secondary-btn btn-sm m-1"
          onClick={() => selectUserSearch(user)}
        >
          Add
        </button>
      );
    } else {
      return (
        <button
          className="btn third-btn btn-sm m-1"
          onClick={() => selectUserSearch(user)}
          style={{ color: "#fff" }}
        >
          Remove
        </button>
      );
    }
  }

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form action="">
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">
                  Module Assignment
                </h2>

                <div className="mt-3">
                  <a>
                    <Link to="/" className="adcm-btn Cancel ms-1">
                      <span>Cancel</span>
                    </Link>
                  </a>
                  <a
                    href="#"
                    className="adcm-btn ms-2"
                    onClick={() => assignModulesToUsers()}
                  >
                    {" "}
                    Save
                  </a>
                  {/* <div className="field-group text-right mt-3" onClick={() => assignModulesToUsers()}>
                                        <button type="submit" className="btn middiumBttn themeColorBttn">Assign</button></div> */}
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">Assignment</h2>

              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main add-module">
                    <div className="company-fillout">
                      <h3 className="fill-heaing">User Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="form-group">
                            <label>User Name</label>

                            <div className="row">
                              <div className="col-auto" style={{ flex: 1 }}>
                                <Search
                                  {...{
                                    data: searchUserList,
                                    onItemSelected: onUserItemSelected,
                                    populateFieldName: "name",
                                    placeholderLabel: "Search Users",
                                    getStringData: getSearchVal,
                                  }}
                                />
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  onClick={searchUsermod}
                                  className="btn btn-success"
                                >
                                  Search
                                </button>
                              </div>
                            </div>

                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedUsers && selectedUsers.length > 0 && (
                                  <div className="flex2fieldForm">
                                    <div className="field-group">
                                      <div className="radioGroup">
                                        {selectedUsers.map((item, index) => (
                                          <div
                                            key={`${item}-${index}`}
                                            className="mr-4 moduleCompetencyCard"
                                          >
                                            <label htmlFor="Module">
                                              {item.name}
                                            </label>
                                            <button
                                              onClick={(e) =>
                                                onRemoveUserClick(e, item._id)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div> */}

                          <div className="form-group">
                            <div className="row">
                              <div className="col-12 mb-3" style={{ flex: 1 }}>
                                <Multiselect
                                  key={JSON.stringify(userSelectData)}
                                  disabled
                                  data={userSelectData || []}
                                  defaultValue={userSelectData}
                                  placeholder="Select user"
                                  textField="moduleName"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-12">
                                <button
                                  type="button"
                                  onClick={searchUser}
                                  className="btn btn-success"
                                >
                                  Select Users
                                </button>
                              </div>
                            </div>
                          </div>

                          <Modal
                            isOpen={modal}
                            toggle={toggle}
                            {...props}
                            className="search-modal"
                          >
                            {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                            <ModalBody>
                              {/* <SearchUser onItemSelected={modifyUserForSearch} /> */}
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <input
                                      ref={inputRef}
                                      type="text"
                                      autoFocus
                                      placeholder="Search users..."
                                      onChange={(event) => {
                                        setsearchTerm(event.target.value);
                                      }}
                                    />
                                    <button
                                      className="custombtnSearch"
                                      onClick={searchUsermod}
                                    >
                                      search
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="list-container">
                                    <ul>
                                      {usersAll &&
                                        usersAll
                                          .filter((val) => {
                                            if (searchTerm == "") {
                                              return val;
                                            } else if (
                                              val.name
                                                ?.toLowerCase()
                                                .includes(
                                                  searchTerm.toLowerCase()
                                                )
                                            ) {
                                              return val;
                                            }
                                          })
                                          .map(function (item, key) {
                                            return (
                                              <li key={key}>
                                                <div
                                                  className="desc"
                                                  style={{ flex: 1 }}
                                                >
                                                  <span className="text-left">
                                                    {item.name}
                                                  </span>
                                                  <span className="textEmail">
                                                    {item.emailAddress}
                                                  </span>
                                                </div>

                                                <div className="text-right">
                                                  <HasUserIdTag user={item} />
                                                </div>
                                              </li>
                                            );
                                          })
                                          .filter((x) => x)}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                        </div>
                      </div>
                    </div>

                    {!loading ? (
                      <></>
                    ) : (
                      <div className="loader-wrapper">
                        <div className="loader"></div>
                      </div>
                    )}
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Module Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Modules</label>
                            <Search
                              {...{
                                data: moduleList,
                                onItemSelected: onModuleItemSelected,
                                populateFieldName: "moduleName",
                                placeholderLabel: "Select module",
                              }}
                            />
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedModules &&
                                  selectedModules.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div className="radioGroup">
                                          {selectedModules.map(
                                            (item, index) => (
                                              <div
                                                key={`${item}-${index}`}
                                                className="mr-4 moduleCompetencyCard"
                                              >
                                                <label htmlFor="Module">
                                                  {item.moduleName}
                                                </label>
                                                <button
                                                  onClick={(e) =>
                                                    onRemoveModuleClick(
                                                      e,
                                                      item._id
                                                    )
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="company-main add-module">
                    <div className="company-fillout">
                    {!loading ?
                      <></>
                      :
                      <div className="loader-wrapper">
                        <div className="loader"></div>
                          
                      </div>

                    }
                    </div>
                  </div>
                </div>

              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    allUsersByCompany: state.users.allUsersByCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentPage);
